import styled, { keyframes } from "styled-components";


const colorShift = keyframes`
    33% {
      border-image: linear-gradient(0deg, #bbf3f8, #19bdef, #baf3fb) 30;
    border-style: solid;
    border-radius: 20px;
    }
    66% {
      border-image: linear-gradient(0deg, #baf3fb, #bbf3f8, #19bdef) 30;
    border-style: solid;
    border-radius: 20px;
    }
    99% {
      border-image: linear-gradient(0deg, #19bdef, #baf3fb, #bbf3f8) 30;
    border-style: solid;
    border-radius: 20px;
    }
`

const rotate = keyframes`
to {
  --angle: 360deg;
}
`

const moveBg = keyframes`
  to {
    background-position: var(--bg-size) 0;
  }
`

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  @media (max-width: 680px) {
    background-image: ${({ imagem }) => (imagem ? `url(${imagem})` : "none")};
    background-size: 100%;
  }
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  border-image: linear-gradient(0deg, #12c2e9, #00eaff, #11ffd3) 30;
  border-color: transparent;
  animation: 3s ${colorShift} linear infinite;
  @media (min-width: 1200px) {
    min-width: 500px;
  }
`;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 18px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--description-text);
  font-size: 16px;
  line-height: 1.6;
`;

export const TextBtmTitle = styled.p`
  font-family: 'Verdana';
  --bg-size: 400%;
  --color-one: #ffffff;
  --color-two: #04f2ff;
  font-size: clamp(1.4rem, 7vmin, 2rem);
  font-weight: 800;
  background: linear-gradient(
                90deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: ${moveBg} 8s infinite linear;
  margin-top: 10px;
`;

export const TextBtmFaq = styled.p`
  --bg-size: 400%;
  --color-one: #ff00f2;
  --color-two: #00ffcc;
  font-size: clamp(1.4rem, 6vmin, 2rem);
  font-weight: 800;
  background: linear-gradient(
                90deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: ${moveBg} 8s infinite linear;
`;

export const TextBtmQ = styled.p`
  --bg-size: 400%;
  --color-one: #00ffea;
  --color-two: #fc59fc;
  font-size: 20px;
  font-weight: 500;
  background: linear-gradient(
                90deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: ${moveBg} 8s infinite linear;
`;

export const TextBtmA = styled.p`
color: var(--description-text);
font-size: 18px;
line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;
