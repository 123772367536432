import React from 'react'
import { SocialIcon } from 'react-social-icons'
import { Variants, motion } from 'framer-motion/dist/framer-motion'
import './styles.css'
import * as s from '../styles/globalStyles'
import styled from 'styled-components'

export const SocialWrapper = styled.div`
  display: flex;
  gap: 0.3rem;
  padding: 5rem;
  padding-bottom: 0.5rem;
`


export const Socials = (props) => {
  return (
    <SocialWrapper>
        {props.telegram != "" &&
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
        <SocialIcon url={props.telegram} fgColor='white' /></motion.div>
        }
        {props.twitter != "" &&
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><SocialIcon url={props.twitter} fgColor='white' key="25"/></motion.div>
        }
        {props.facebook != "" &&
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><SocialIcon url={props.facebook} fgColor='white' key="30"/></motion.div>
        }
        {props.discord != "" &&
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><SocialIcon url={props.discord} fgColor='white' key="2355"/></motion.div>
        }
        {props.other != "" &&
         <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><SocialIcon url={props.other} fgColor='white' key="40"/></motion.div>
        }
        {props.othertwo != '' &&
         <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><SocialIcon url={props.othertwo} fgColor='white' key="45"/></motion.div>
        }
    </SocialWrapper>
  )
}

export default Socials