import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from './redux/blockchain/blockchainActions'
import { fetchData } from './redux/data/dataActions'
import * as s from './styles/globalStyles'
import styled, { keyframes } from 'styled-components'
import { Variants, motion } from 'framer-motion/dist/framer-motion'
import Socials from './components/Socials'

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: var(--button-border-radius);
  border: var(--button-border-style);
  border-color: var(--button-border-color);
  border-width: var(--button-border-width);
  background-color: var(--button-color);
  padding: 10px;
  font-weight: bold;
  font-family: var(--font-family-button);
  color: var(--button-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, var(--button-shadow-alpha));
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, var(--button-shadow-alpha));
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, var(--button-shadow-alpha));
  :hover {
    background-color: var(--button-hover-color);
    color: var(--button-text-hover-color);
    border-color: transparent;
  }
  :hover:after {
    content: '';
    position: absolute;
    background: linear-gradient(90deg, #fc7600, #ff0011, #5073b8, #1098ad, #6fba82);
    height: calc(100% + 3px * 4);
    width: calc(100% + 3px * 4);
    top: calc(-3px * 2);
    left: calc(-3px * 2);
    z-index: -1;
    border-radius: 15px;
    background-size: 200%;
    animation: animate 1s ease infinite alternate;
}
@keyframes  animate {
  from {
      background-position: 0% 50%;
  }
  to {
      background-position: 100% 50%;
  }
}
  :active {
    background-color: var(--button-active-color);
  }
`

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--round-button-bg);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  font-family: var(--font-family);
  color: var(--round-button-fg);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, var(--button-shadow-alpha));
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, var(--button-shadow-alpha));
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, var(--button-shadow-alpha));

  :hover {
    background-color: var(--button-hover-color);
    color: var(--button-text-hover-color);
    border-color: transparent;
  }
  :hover:after {
    content: '';
    position: absolute;
    background: linear-gradient(90deg, #fc7600, #ff0011, #5073b8, #1098ad, #6fba82);
    height: calc(100% + 3px * 4);
    width: calc(100% + 3px * 4);
    top: calc(-3px * 2);
    left: calc(-3px * 2);
    z-index: -1;
    border-radius: 100%;
    background-size: 200%;
    animation: animate 1s ease infinite alternate;
}
@keyframes  animate {
  from {
      background-position: 0% 50%;
  }
  to {
      background-position: 100% 50%;
  }
}
  :active {
    background-color: var(--button-active-color);
  }
`

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`

export const ResponsiveWrapper2 = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20rem;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`

export const StyledLogo = styled.img`
  width: 300px;
  @media (min-width: 767px) {
    width: 375x;
    margin-top: 40px;
    margin-bottom: -80px;
  }
  margin-bottom: -150px;
  transition: width 0.5s;
  transition: height 0.5s;
`

export const StyledImg = styled.img`
  box-shadow: var(--shadow-offset-x) var(--shadow-offset-y)
    var(--shadow-blur-radius) var(--shadow-spread-radius)
    rgba(var(--shadow-r), var(--shadow-g), var(--shadow-b), var(--shadow-alpha));
  border: var(--border-width-gif) var(--border-style-gif)
    var(--border-color-gif);
  background-color: var(--accent2);
  border-radius: var(--secondary-corner-radius);
  width: 300px;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1000px) {
    width: 350;
  }
  transition: width 0.5s;
`

export const StyledImg2 = styled.img`
  width: 300px;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1000px) {
    width: 350;
  }
  margin-bottom: 30px;
  transition: width 0.5s;
`

export const StyledImgFlipped = styled(StyledImg)`
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`

export const StyledImgMobile = styled(StyledImg)`
  @media (max-width: 768px) {
    display: none;
  }
`

export const StyledImgFlippedMobile = styled(StyledImgFlipped)`
  @media (max-width: 768px) {
    display: none;
  }
`

export const StyledLink = styled.a`
  transition: all .2s ease-in-out;
  color: var(--link);
  background: -webkit-linear-gradient(0deg, var(--text-grad1), var(--text-grad2), var(--text-grad3));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }
  :hover {
    color: var(--link-hover);
    background: -webkit-linear-gradient(0deg, var(--text-grad3), var(--text-grad2), var(--text-grad1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`

const moveBg = keyframes`
  to {
    background-position: var(--bg-size) 0;
  }
`

const moveBgR = keyframes`
  to {
    background-position: var(--bg-size) 0;
  }
`

export const StyledLink1 = styled.a`
  --bg-size: 400%;
  --color-one: #00c3ff;
  --color-two: #00fff2;
  --color-three: #ffffff;
  --color-link1: #00e1ff;
  --color-link2: #0dff00ff;
  text-shadow: 1px 1px #0000002d;
  color: var(--link);
  background: linear-gradient(
    30deg,
    var(--color-one),
    var(--color-two),
    var(--color-three),
    var(--color-one),
    var(--color-two),
    var(--color-three),
    var(--color-one)
  ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: ${moveBgR} 15s infinite linear reverse;
  :hover {
    color: transparent;
    background: -webkit-linear-gradient(60deg, var(--color-link1), var(--color-link2), var(--color-link1), var(--color-link2), var(--color-link1), var(--color-link2), var(--color-link1)) 0 0 / var(--bg-size) 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${moveBgR} 3s infinite ease-in-out reverse;
    text-decoration: underline overline solid #1aff009c;
    text-underline-offset: 5px;
  }
`

export const StyledLink2 = styled.a`
  --bg-size: 400%;
  --color-one: #ff00c3;
  --color-two: #ffff00;
  --color-three: #00faf6;
  --color-link1: #ddff00;
  --color-link2: #00fff2ff;
  color: var(--link);
  background: linear-gradient(
    30deg,
    var(--color-one),
    var(--color-two),
    var(--color-three),
    var(--color-one),
    var(--color-two),
    var(--color-three),
    var(--color-one)
  ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: ${moveBgR} 15s infinite linear reverse;
  :hover {
    color: transparent;
    background: -webkit-linear-gradient(60deg, var(--color-link1), var(--color-link2), var(--color-link1), var(--color-link2), var(--color-link1), var(--color-link2), var(--color-link1)) 0 0 / var(--bg-size) 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${moveBgR} 3s infinite ease-in-out reverse;
  }
`


export const StyledLink3 = styled.a`
--bg-size: 400%;
--color-one: #ff00c3;
--color-two: #ffff00;
--color-three: #00faf6;
--color-link1: #26ff00;
--color-link2: #fa4f4fff;
font-size: clamp(1.4rem, 5vmin, 1rem);
font-weight: 800;
background: linear-gradient(
              90deg,
              var(--color-one),
              var(--color-two),
              var(--color-three),
              var(--color-one),
              var(--color-two),
              var(--color-three),
              var(--color-one),
              var(--color-two),
              var(--color-three),
              var(--color-one)
            ) 0 0 / var(--bg-size) 100%;
color: transparent;
background-clip: text;
-webkit-background-clip: text;
animation: ${moveBgR} 15s infinite linear;
:hover {
  color: transparent;
  background: -webkit-linear-gradient(60deg, var(--color-link1), var(--color-link2), var(--color-link1), var(--color-link2), var(--color-link1), var(--color-link2), var(--color-link1)) 0 0 / var(--bg-size) 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${moveBgR} 3s infinite ease-in-out;
}
`

export const StyledLink4 = styled.a`
--bg-size: 400%;
--color-one: #ff00c3;
--color-two: #ffff00;
--color-three: #00faf6;
--color-link1: #26ff00;
--color-link2: #fa4f4fff;
font-size: clamp(1.4rem, 5vmin, 1rem);
font-weight: 800;
background: linear-gradient(
              90deg,
              var(--color-one),
              var(--color-two),
              var(--color-three),
              var(--color-one),
              var(--color-two),
              var(--color-three),
              var(--color-one),
              var(--color-two),
              var(--color-three),
              var(--color-one)
            ) 0 0 / var(--bg-size) 100%;
color: transparent;
background-clip: text;
-webkit-background-clip: text;
animation: ${moveBgR} 15s infinite linear reverse;
:hover {
  color: transparent;
  background: -webkit-linear-gradient(60deg, var(--color-link1), var(--color-link2), var(--color-link1), var(--color-link2), var(--color-link1), var(--color-link2), var(--color-link1)) 0 0 / var(--bg-size) 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${moveBgR} 3s infinite ease-in-out reverse;
}
`

const images = [
  'players.gif',
]

export function RandomWelcomePicture() {
  const [currentImageIndex, setCurrentImageIndex] = useState(Math.floor(Math.random() * images.length))
  const changeImage = () => {
    const randomNumber = Math.floor(Math.random() * images.length);
    setCurrentImageIndex(randomNumber);
  }
  useEffect(() => changeImage(), [])

  return (
    <StyledImg2 alt={'example'} src={`/config/images/${images[currentImageIndex].toString()}`} />
  )
}

const Preview = (props) => {
  if (props.side == "left" && props.flip == "left") {
    return (<StyledImgFlippedMobile alt={'example'} src={'/config/images/example1.gif'} />)
  } else if (props.side == "left" && props.flip != "left") {
    return (<StyledImgMobile alt={'example'} src={'/config/images/example1.gif'} />)
  } else if (props.side == "right" && props.flip == "right") {
    if (!props.mobile) {
      return (<StyledImgFlipped alt={'example'} src={'/config/images/example1.gif'} />)
    } else {
      return (<StyledImgFlippedMobile alt={'example'} src={'/config/images/example1.gif'} />)
    }
  } else if (props.side == "right" && props.flip != "right") {
    if (!props.mobile) {
      return (<StyledImg alt={'example'} src={'/config/images/example1.gif'} />)
    } else {
      return (<StyledImgMobile alt={'example'} src={'/config/images/example1.gif'} />)
    }
  } else {
    return (<StyledImgMobile alt={'example'} src={'/config/images/example1.gif'} />)
  }
}

function App() {
  const dispatch = useDispatch()
  const blockchain = useSelector(state => state.blockchain)
  const data = useSelector(state => state.data)
  const [claimingNft, setClaimingNft] = useState(false)
  const [feedback, setFeedback] = useState(`Select Quantity, Click Buy to Mint!`)
  const [mintAmount, setMintAmount] = useState(1)
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: '',
    SCAN_LINK: '',
    NETWORK: {
      NAME: '',
      SYMBOL: '',
      ID: 0
    },
    NFT_NAME: '',
    SYMBOL: '',
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: '',
    MARKETPLACE_LINK: '',
    TELEGRAM: '',
    TWITTER: '',
    FACEBOOK: '',
    DISCORD: '',
    OTHERSOCIAL: '',
    OTHERTWO: '',
    SHOW_BACKGROUND: false,
    TWO_PREVIEW_MOBILE: true,
    PREVIEWFLIP: "none"
  })

  const claimNFTs = () => {
    const mintMessages = [
      `Scoring points with ${CONFIG.NFT_NAME}...`,
      `PEW PEW ${CONFIG.NFT_NAME}!`,
      `Minting ${CONFIG.NFT_NAME} with haste!`,
      `New Year, New ${CONFIG.NFT_NAME}...`,
      `All your ${CONFIG.NFT_NAME} are belong to us!`,
    ]
    const randomMessageNum = Math.floor(Math.random() * mintMessages.length);
    let cost = CONFIG.WEI_COST
    let gasLimit = CONFIG.GAS_LIMIT
    let totalCostWei = String(cost * mintAmount)
    let totalGasLimit = String(gasLimit * mintAmount)
    console.log('Cost: ', totalCostWei)
    console.log('Gas limit: ', totalGasLimit)
    setFeedback(`${mintMessages[randomMessageNum]}`)
    setClaimingNft(true)

    const errorMsg = [
      'Cannot Compute! Error in minting!',
      `Error or user rejected ${CONFIG.NFT_NAME} TX man, not cool!`,
      'Mint TX failed, bummer dude! Try try again fren!',
      'Hmm something went wrong. Did you try blowing on the cartridge?',
    ]
    const randomErrorNum = Math.floor(Math.random() * errorMsg.length);


    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei
      })
      .once('error', err => {
        console.log(err)
        setFeedback(`🚨 ${errorMsg[randomErrorNum]}`)
        setClaimingNft(false)
      })
      .then(receipt => {
        console.log(receipt)
        setFeedback(
          `The ${CONFIG.NFT_NAME} is yours! go visit ${CONFIG.MARKETPLACE} to view it.`
        )
        setClaimingNft(false)
        dispatch(fetchData(blockchain.account))
      })
  }

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1
    if (newMintAmount < 1) {
      newMintAmount = 1
    }
    setMintAmount(newMintAmount)
  }

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1
    if (newMintAmount > 10) {
      newMintAmount = 10
    }
    setMintAmount(newMintAmount)
  }

  const getData = () => {
    if (blockchain.account !== '' && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account))
    }
  }

  const getConfig = async () => {
    const configResponse = await fetch('/config/config.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
    const config = await configResponse.json()
    SET_CONFIG(config)
  }

  useEffect(() => {
    getConfig()
  }, [])

  useEffect(() => {
    getData()
  }, [blockchain.account])

  const previewVariants = {
    offscreen: {
      y: 300
    },
    onscreen: {
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={'center'}
        style={{ padding: 1, backgroundColor: 'var(--primary)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
        image={CONFIG.SHOW_BACKGROUND ? '/config/images/bg.png' : null}
        imagem={CONFIG.SHOW_BACKGROUND ? '/config/images/bgm.png' : null}
      >
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.8, rotate: -180 }}
          initial={{ opacity: 0, scale: 0.2 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            default: {
              duration: 0.6,
              ease: [0, 0.71, 0.2, 1.01]
            },
            scale: {
              type: "spring",
              damping: 8,
              stiffness: 300,
              restDelta: 0.001
            }
          }}
        >
          <StyledLogo alt={'logo'} src={'/config/images/logo.png'} />
        </motion.div>
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={'center'} ai={'center'}>
            <motion.div
              whileHover={{ scale: 1.1, rotate: -10 }}
              whileTap={{ scale: 1.2, rotate: 10 }}
              transition={{ type: "spring", stiffness: 400 }}
              initial="offscreen"
              animate="onscreen"
              viewport={{ once: true, amount: 0.8 }}
              variants={previewVariants}
            >
              <Preview side='left' flip={CONFIG.PREVIEWFLIP} mobile={CONFIG.TWO_PREVIEW_MOBILE} />
            </motion.div>
          </s.Container>
          <s.SpacerLarge />
          <motion.div
            initial={{ opacity: 0, scale: 0.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              delay: 0.2,
              default: {
                duration: 0.6,
                ease: [0, 0.71, 0.2, 1.01]
              },
            }}>
            <s.MiddleContainer
              flex={2}
              jc={'center'}
              ai={'center'}
              style={{
                backgroundColor: 'var(--accent)',
                padding: 24,
                borderRadius: 'var(--primary-corner-radius)',
                border:
                  'var(--border-width) var(--border-style) var(--border-color)',
                borderImage: 'linear-gradient(0deg, #bbf3f8, #19bdef, #baf3fb) 30',
                boxShadow:
                  'var(--shadow-offset-x) var(--shadow-offset-y) var(--shadow-blur-radius) var(--shadow-spread-radius) rgba(var(--shadow-r),var(--shadow-g),var(--shadow-b),var(--shadow-alpha))'
              }}
            >
              <s.TextTitle
                style={{
                  textAlign: 'center',
                  fontSize: 50,
                  fontWeight: 'bold',
                  fontFamily: 'var(--font-family)',
                  color: 'var(--accent-text)'
                }}
              >
                {data.totalSupply} / {CONFIG.MAX_SUPPLY}
              </s.TextTitle>
              <s.TextDescription
                style={{
                  textAlign: 'center',
                  color: 'var(--primary-text)'
                }}
              >
                <StyledLink1 target={'_blank'} href={CONFIG.SCAN_LINK}>
                  Open in Shibariumscan
                </StyledLink1>
              </s.TextDescription>
              <s.SpacerSmall />
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <>
                  <s.TextTitle
                    style={{ textAlign: 'center', color: 'var(--accent-text)' }}
                  >
                    The sale has ended.
                  </s.TextTitle>
                  <s.TextDescription
                    style={{ textAlign: 'center', color: 'var(--accent-text)' }}
                  >
                    You can still find {CONFIG.NFT_NAME} on
                  </s.TextDescription>
                  <s.SpacerSmall />
                  <StyledLink target={'_blank'} href={CONFIG.MARKETPLACE_LINK}>
                    {CONFIG.MARKETPLACE}
                  </StyledLink>
                </>
              ) : (
                <>
                  <s.TextTitle
                    style={{ textAlign: 'center', color: 'var(--accent-text)' }}
                  >
                    1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{' '}
                    {CONFIG.NETWORK.SYMBOL}.
                  </s.TextTitle>
                  <s.TextDescription
                    style={{ textAlign: 'center', color: 'var(--accent-text)', fontSize: 14, paddingTop: 0 }}
                  >
                    (Excluding ⛽️ Gas Fees.)
                  </s.TextDescription>
                  <s.SpacerSmall />
                  {blockchain.account === '' ||
                    blockchain.smartContract === null ? (
                    <s.Container ai={'center'} jc={'center'}>
                      <s.TextDescription
                        style={{
                          textAlign: 'center',
                          color: 'var(--accent-text)'
                        }}
                      > Insert tokens to mint ShibArcade NFTs
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <StyledButton
                          onClick={e => {
                            e.preventDefault()
                            dispatch(connect())
                            getData()
                          }}
                        >
                          CONNECT
                        </StyledButton>
                      </motion.div>
                      {blockchain.errorMsg !== '' ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: 'center',
                              color: 'var(--accent-text)'
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <>
                      <s.TextDescription
                        style={{
                          textAlign: 'center',
                          color: 'var(--accent-text)'
                        }}
                      >
                        {feedback}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <s.Container ai={'center'} jc={'center'} fd={'row'}>
                        <motion.div
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          <StyledRoundButton
                            style={{ lineHeight: 0.4 }}
                            disabled={claimingNft ? 1 : 0}
                            onClick={e => {
                              e.preventDefault()
                              decrementMintAmount()
                            }}
                          >
                            -
                          </StyledRoundButton>
                        </motion.div>
                        <s.SpacerMedium />
                        <s.TextDescription
                          style={{
                            textAlign: 'center',
                            color: 'var(--accent-text)'
                          }}
                        >
                          {mintAmount}
                        </s.TextDescription>
                        <s.SpacerMedium />
                        <motion.div
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          <StyledRoundButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={e => {
                              e.preventDefault()
                              incrementMintAmount()
                            }}
                          >
                            +
                          </StyledRoundButton>
                        </motion.div>
                      </s.Container>
                      <s.SpacerSmall />
                      <s.Container ai={'center'} jc={'center'} fd={'row'}>
                        <motion.div
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={e => {
                              e.preventDefault()
                              claimNFTs()
                              getData()
                            }}
                          >
                            {claimingNft ? 'BUSY' : 'BUY'}
                          </StyledButton>
                        </motion.div>
                      </s.Container>
                    </>
                  )}
                </>
              )}
              <s.SpacerMedium />
            </s.MiddleContainer>
          </motion.div>
          <s.SpacerLarge />
          <s.Container flex={1} jc={'center'} ai={'center'}>
            <motion.div
              whileHover={{ scale: 1.1, rotate: -10 }}
              whileTap={{ scale: 1.2, rotate: 10 }}
              transition={{ type: "spring", stiffness: 400 }}
              initial="offscreen"
              animate="onscreen"
              viewport={{ once: true, amount: 0.8 }}
              variants={previewVariants}
            >
              <Preview side='right' flip={CONFIG.PREVIEWFLIP} mobile={CONFIG.TWO_PREVIEW_MOBILE} />
            </motion.div>
          </s.Container>
        </ResponsiveWrapper>
        <Socials telegram={CONFIG.TELEGRAM} facebook={CONFIG.FACEBOOK} discord={CONFIG.DISCORD} twitter={CONFIG.TWITTER} other={CONFIG.OTHERSOCIAL} othertwo={CONFIG.OTHERTWO} />

      </s.Container>
      <s.Container
        flex={1}
        ai={'center'}
        jc={'center'}
        style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 8, backgroundColor: 'black', backgroundRepeat: 'repeat' }}
      >
        <s.Container flex={2} ai={'center'} jc={'center'} style={{ maxWidth: '600px', paddingTop: '0px', paddingBottom: '10px' }}>
          <s.TextBtmTitle
            style={{
              textAlign: 'center',
            }}
          >
            Welcome to D-CENT SHIBARCADE!
          </s.TextBtmTitle>
          <s.SpacerSmall />
          <s.TextDescription>
          Brought to you by D-Cent Gamers and Baby Brick!
          </s.TextDescription>
          <s.SpacerLarge />
          <s.TextBtmFaq
            style={{
              textAlign: 'center',
            }}
          >
            ABOUT SHIBARCADE
          </s.TextBtmFaq>
          <s.SpacerMedium />
          <s.TextBtmQ>
            Our Inspiration
          </s.TextBtmQ>
          <s.SpacerSmall />
          <s.TextBtmA>
            Believe it or not, not to long ago, there was a time where not every household had gaming consoles! If you were lucky, you had one and had to use it on the one T.V. in the house! This was before everyone had a computer in their pocket with more computing power than NASA used for the Apollo missions. Back then, we had to trudge over to the local arcade, or the mall, meet our friends, and pool together all the quarters we could find or pull out of the couch cushions. Spending hours in dimly lit halls full of the noise of games and fun, and the flashing lights of the screens and neon decor.
            In the Shibarium Communities We see that same sense of camaraderie of a group of friends helping each other reach those elusive high scores. So We put together this collection in what we feel best exemplifies that friendly competition and cooperation that you saw in those arcades of days gone by.
          </s.TextBtmA>

          <s.SpacerMedium />
          <s.TextBtmQ>
            Mint Party!
          </s.TextBtmQ>
          <s.SpacerSmall />
          <s.TextBtmA>
            Stay tuned because during the minting period we may be offering random giveaways to minters and holders of Baby Brick token!
          </s.TextBtmA>

          <s.SpacerMedium />
          <s.TextBtmQ>
            Utility
          </s.TextBtmQ>
          <s.SpacerSmall />
          <s.TextBtmA>
            We are planning on having staking for this series of NFTs.
            Legendary Cards will have a place in our ecosystem.
            Community Cards are up for 100 $BONE bounty
          </s.TextBtmA>

          <s.SpacerMedium />
          <s.TextBtmQ>
            Legends in the Making
          </s.TextBtmQ>
          <s.SpacerSmall />
          <s.TextBtmA>
            8 Legendary Player Cards which will have a place in our future plans.
            11 Community cards, with a special side panel, these are specific for the different communities, We will offer 100 $BONE as a bounty for these cards and then we will send them to the community that it represents, Or.... you can ask that community for ransom if you're feeling devious!
          </s.TextBtmA>

          <s.SpacerMedium />
          <s.TextBtmQ>
            DISCLAIMER:
          </s.TextBtmQ>
          <s.SpacerSmall />
          <s.TextBtmA>
            Minting a ShibArcade NFT is a non-refundable transaction
            ShibArcade is not to be considered a financial asset, or an investment so proceed with caution. Just like gaming, Minting NFTs is a fun and fulfilling hobby, but it can be addicting. We have regular meetings in our telegram group, come on in, grab some coffee and play some games!
          </s.TextBtmA>
          <s.SpacerLarge />
          <s.TextBtmA style={{
            textAlign: 'center',
          }}>
            Built in Collaboration with: <StyledLink3 target={'_blank'} href={'https://chewyswap.dog'}>ChewySwap</StyledLink3> <StyledLink4 target={'_blank'} href={'https://t.me/ChewySwapCommunity'}>NFT Incubator</StyledLink4>
          </s.TextBtmA>

          <s.SpacerLarge />
          <s.SpacerMedium />
          <RandomWelcomePicture />
        </s.Container>
      </s.Container>
    </s.Screen>
  )
}

export default App
